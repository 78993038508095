import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// 各个国家的key
export const localeKeys = [
  { key: "en", name: "English" }, // 英语
  { key: "zh-CN", name: "中文" }, // 中文
];

// 各个国家语言包
const messages = {};
for (const item of localeKeys) {
  const key = item.key;
  const langObj = require(`@/components/i18n/locales/${key}/index.js`).default;
  const langElement = require(`@/components/i18n/element/${key}`);
  messages[key] = {
    ...langObj,
    ...(langElement ? langElement.default : {}),
  };
}

export default new VueI18n({
  locale: localStorage.getItem("change-language") || "zh-CN",
  messages,
  silentTranslationWarn: true, // 忽略翻译警告
});
