<template>
  <transition name="fade">
    <Dialog :title="title" v-if="dialog_show" @close="handleClose">
      <div class="login">
        <!-- 用户名 -->
        <div class="user" v-if="show.user">
          <i class="iconfont icon-yonghuming"></i>
          <input type="text" v-model.trim="form.username" :placeholder="$t('username')" />
        </div>

        <!-- 密码 -->
        <div class="password" v-if="show.password">
          <i class="iconfont icon-mima"></i>
          <input type="text" v-model.trim="form.password" :placeholder="$t('password')" />
        </div>

        <!-- 手机号 -->
        <div class="phone" v-if="show.phone">
          <i class="iconfont icon-shoujihao"></i>
          <input type="text" v-model.trim="form.phone" :placeholder="$t('phone')" />
        </div>

        <!-- 验证码 -->
        <div class="msgcode" v-if="show.msgcode">
          <i class="iconfont icon-yanzhengma"></i>
          <input type="text" v-model.trim="form.msgcode" :placeholder="$t('code')" />
          <LibValidationCountdown @change="statusChange" ref="countdown">
            <template v-slot="{ text, disabled }">
              <button
                class="send"
                @click="handleGetCode"
                :disabled="disabled"
                :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
              >
                {{ text }}
              </button>
            </template>
          </LibValidationCountdown>
        </div>

        <!-- 昵称 -->
        <div class="nickname" v-if="show.nickname">
          <i class="iconfont icon-nicheng"></i>
          <input type="text" v-model.trim="form.name" :placeholder="$t('nickname')" />
        </div>
      </div>

      <!-- 登录、注册 -->
      <div class="btns">
        <div class="log click" @click="handleLogin" v-if="show.log">{{ $t("login") }}</div>
        <div class="reg click" @click="handleRegister" v-if="show.reg">{{ $t("signUp") }}</div>
      </div>

      <!-- 登录方式 -->
      <div class="way">
        <span @click="handleToggle('a')">{{ $t("passwordLogin") }}</span>
        <span @click="handleToggle('b')">{{ $t("codeLogin") }}</span>
        <span @click="handleToggle('c')">{{ $t("signUp") }}</span>
      </div>
    </Dialog>
  </transition>
</template>
<script>
import { mapActions } from "vuex";
import { register, sendCode } from "@/api/index.js";
export default {
  data() {
    return {
      dialog_show: true,

      /* 登录方式切换 */
      show: {
        user: true,
        password: true,
        phone: false,
        msgcode: false,
        nickname: false,
        log: true,
        reg: false,
      },

      /* 表单 */
      form: {
        name: "",
        password: "",
        phone: "",
        username: "",
        msgcode: "",
      },
      send_status: true, //是否可发送二维码
      login_type: "password", //用于切换登录方式
    };
  },
  computed: {
    title() {
      return "" + (this.show.reg ? this.$t("signUp") : this.$t("login"));
    },
  },
  methods: {
    ...mapActions("user", {
      StoreLogin: "login",
      StoreLoginCode: "loginCode",
    }),

    /* 注册 */
    handleRegister() {
      register(this.form).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(this.$t("success"));
          this.toggle("a");
        }
      });
    },

    /* 登录 */
    handleLogin() {
      if (this.login_type === "password") {
        // 密码登录
        this.StoreLogin(this.form).then(() => {
          this.$emit("close");
        });
      } else {
        // 验证码登录
        this.StoreLoginCode(this.form).then(() => {
          this.$emit("close");
        });
      }
    },

    /* 切换登录方式 */
    handleToggle(status) {
      const obj = {
        a: {
          user: true,
          password: true,
          phone: false,
          msgcode: false,
          nickname: false,
          log: true,
          reg: false,
        },
        b: {
          user: false,
          password: false,
          phone: true,
          msgcode: true,
          nickname: false,
          log: true,
          reg: false,
        },
        c: {
          user: true,
          password: true,
          phone: true,
          msgcode: false,
          nickname: true,
          log: false,
          reg: true,
        },
      };

      const type = {
        a: "password",
        b: "code",
      };
      this.login_type = type[status];
      this.show = obj[status];
    },

    /* 关闭登录窗口 */
    handleClose() {
      this.$emit("close");
    },

    /* 获取验证码 */
    handleGetCode() {
      if (this.send_status) {
        sendCode(this.form.phone).then((res) => {
          if (res.data.data) {
            this.$message.success(this.$t("success"));
            this.$refs.countdown.start();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },

    /* 验证码按钮状态 */
    statusChange(status) {
      this.send_status = status;
    },
  },
};
</script>
<style scoped lang="less">
.login {
  width: 100%;
  .user,
  .password,
  .phone,
  .msgcode,
  .nickname {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray);
    margin-bottom: 15px;
    .iconfont {
      color: var(--theme-color-one);
      font-size: 25px;
      margin-right: 10px;
    }
    input {
      flex: 1;
      color: var(--white);
      font-size: 16px;
      background-color: transparent;
      border: none;
    }
    .send {
      background-color: var(--theme-color-six);
      color: var(--white);
      padding: 5px 10px;
      border: none;
    }
  }
}
.btns {
  margin: 30px 0;
  .log,
  .reg {
    width: 300px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: var(--white);
    background-color: var(--theme-color-six);
  }
}
.way {
  span {
    margin: 0 8px;
    color: var(--gray);
    cursor: pointer;
    &:hover {
      color: var(--gray-a);
    }
  }
}
@media screen and (max-width: 960px) {
  .login {
    width: 100%;
    .user,
    .password,
    .phone,
    .msgcode,
    .nickname {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--gray);
      margin-bottom: 15px;
      .iconfont {
        color: var(--theme-color-one);
        font-size: 25px;
        margin-right: 10px;
      }
      input {
        flex: 1;
        color: var(--white);
        font-size: 16px;
        background-color: transparent;
        border: none;
      }
      input {
        width: 200px;
        font-size: 16px;
      }
    }

    .msgcode {
      input {
        width: 100px;
      }
      .send {
        padding: 5px;
      }
    }
  }
  .way {
    font-size: 14px;
    span {
      margin: 0 5px;
    }
  }
}
.btns {
  margin: 30px 0;
  .log,
  .reg {
    width: 150px;
  }
}
</style>
