export default {
  Language: "中文",
  mapz: "Mapz在线地图",
  changeLanguage: "点击切换语言",
  isLease: "是否为租赁商品",
  category: "分类",
  leaseTime: "租赁时长(秒)",
  quantity: "数量",
  payType: "支付方式",
  deliveryType: "发货方式",
  direct: "直接发货",
  cdk: "CDK发货",
  total: "共计",
  pay: "购买",
  yes: "是",
  no: "否",
  noBindSteamError: "没有Steam帐号，无法直接发货",
  scoreNotEnoughError: "您的积分不足以支付",
  createOrderFailed: "创建订单失败",
  directNotice: "请确认您在服务器中，并尽量避免服务器重启前的几分钟支付",
  validityPeriod: "有效期",
  supportPayMethod: "支持以上扫码方式",
  neededScores: "需要支付的积分",
  confirmPay: "确认支付",
  successPay: "支付成功",
  cdkNotice: "这是您的兑换码，请妥善保管",
  directTip: "请前往游戏查收",
  confirm: "确定",
  copyBtn: "点击复制",
  attention: "提示",
  closeNotice: "确认关闭?",
  cancle: "取消",
  success: "成功",
  serverIdNotFund: "未携带服务器id，请携带后再打开此网页",
  map: "地图",
  port: "端口",
  price: "价格",
  score: "积分",
  menu: "菜单",
  product: "商品",
  name: "名称",
  orderNo: "订单号",
  totalPrice: "总价",
  money: "人民币",
  login: "登录",
  signUp: "注册",
  passwordLogin: "账号密码登录",
  codeLogin: "验证码登录",
  username: "用户名",
  password: "密码",
  phone: "手机号",
  code: "验证码",
  nickname: "昵称",
  orders: "订单列表",
  profiles: "个人中心",
  bindSteam: "绑定/换绑Steam",
  logout: "退出登录",
  home: "首页",
  shopInfo: "店铺信息",
  notice: "店铺公告",
  contact: "联系方式",
};
