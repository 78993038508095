<template>
  <div class="home-header">
    <!-- logo -->
    <img class="logo" src="/img/logo.png" @click="handleHome" />

    <!-- 展开侧边栏 -->
    <i class="unfold iconfont icon-zhedie2" v-if="showUnfoldBtn" @click="StoreSetSidebarShow(true)"></i>

    <!-- 输入框 -->
    <div class="input" v-if="$route.path === '/home'">
      <input type="text" :placeholder="placeholder" @input="search" />
    </div>

    <!-- 回到主页 -->
    <div class="home" @click="handleHome" v-if="hideHomeBtn">
      <i class="iconfont icon-shouye"></i>
      <span>{{ $t("home") }}</span>
    </div>

    <!-- 登录 -->
    <div class="login click" v-if="!state_user_status" @click="handleLogin">{{ $t("login") }}</div>

    <!-- 用户信息 -->
    <div class="user" v-if="state_user_status">
      <div class="info">
        <div class="score">
          <i class="iconfont icon-jifen"></i>
          <span>{{ state_user_score }}</span>
        </div>
      </div>
      <img @click="handUserInfo" :src="headImg" alt="" />
      <span class="name">{{ state_user_info.name }}</span>
      <div class="menu">
        <span class="name" v-if="state_steam_info">{{ state_steam_info.personaname }}</span>
        <div class="btns">
          <div class="menu-item" @click="handleUserCenter">{{ $t("profiles") }}</div>
          <div class="menu-item" @click="handleBindSteam">{{ $t("bindSteam") }}</div>
          <div class="menu-item" @click="StoreLogout(false)">{{ $t("logout") }}</div>
        </div>
      </div>
    </div>

    <!-- 用户信息(弹框) -->
    <transition name="fade">
      <div class="user-box" v-if="state_userinfo_show">
        <div class="top">
          <img :src="headImg" alt="" />
          <span class="name">{{ state_user_info.name }}</span>
        </div>
        <span class="score">
          <i class="iconfont icon-jifen"></i>
          <span>{{ state_user_score }}</span>
        </span>

        <div class="btns">
          <div class="menu-item" @click="handleUserCenter">{{ $t("profiles") }}</div>
          <div class="menu-item" @click="handleBindSteam">{{ $t("bindSteam") }}</div>
          <div class="menu-item" @click="StoreLogout(false)">{{ $t("logout") }}</div>
        </div>
      </div>
    </transition>

    <!-- 登录弹窗 -->
    <transition name="fade">
      <Login v-if="show_login" @close="show_login = false" />
    </transition>

    <!-- 绑定Steam弹窗 -->
    <transition name="fade">
      <BindSteam :user-id="state_user_info.userId" v-if="show_bind" @close="show_bind = false" />
    </transition>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { $debounceDelay } from "@/utils/index.js";
import Login from "./childComps/Login/index.vue";
import BindSteam from "./childComps/BindSteam/index.vue";
export default {
  name: "HomeHeader",
  data() {
    return {
      show_login: false, //是否显示登录弹窗
      show_bind: false, //是否显示绑定Steam弹窗
    };
  },
  components: { Login, BindSteam },
  computed: {
    ...mapState("user", {
      state_steam_info: "steam_info", //steam信息
      state_user_status: "user_status", //登录状态
      state_user_score: "user_score", //用户积分
      state_user_info: "user_info", //用户信息信息
    }),
    ...mapState("goods", {
      state_goods_type: "goods_type", //商品类型值
      state_goods_type_name: "goods_type_name", //商品类型名
      state_goods_type_name_list: "goods_type_name_list", //商品类型值
    }),
    ...mapState("global", {
      state_screen_width: "screen_width", //设备宽度
      state_userinfo_show: "userinfo_show", //显示用户信息
      state_sidebar_show: "sidebar_show", //显示用户信息
    }),

    /* 输入框描述 */
    placeholder() {
      return "搜索" + this.state_goods_type_name;
    },

    /* 显示展开侧边栏按钮 */
    showUnfoldBtn() {
      return this.$route.path === "/home";
    },

    /* 隐藏回到首页按钮 */
    hideHomeBtn() {
      return this.$route.path !== "/home";
    },

    /* 显示头像 */
    headImg() {
      return (this.state_steam_info && this.state_steam_info.avatarfull) || require("./img/head.png");
    },
  },
  created() {
    /* 是否显示登录框 */
    this.$bus.$on("login-tip", () => {
      this.show_login = true;
    });
  },
  methods: {
    ...mapMutations("user", {
      StoreLogout: "logout", //退出登录
    }),
    ...mapMutations("goods", {
      StoreSetGoodsName: "setGoodsName", //设置商品搜索名称
    }),
    ...mapMutations("global", {
      StoreSetUserinfoShow: "setUserinfoShow", //设置显示/隐藏用户信息
      StoreSetSidebarShow: "setSidebarShow", //设置显示/隐藏侧边栏
    }),
    ...mapActions("goods", {
      StoreGetGoodsList: "getGoodsList", //获取商品列表
    }),

    /* 登录 */
    handleLogin() {
      this.show_login = true;
    },

    /* 回到首页 */
    handleHome() {
      this.$router.push("/home");
    },

    /* 打开个人中心 */
    handleUserCenter() {
      this.$router.push("/personal-center");
      this.StoreSetUserinfoShow(false);
    },

    /* 搜索 */
    search(e) {
      $debounceDelay(() => {
        this.StoreSetGoodsName(e.target.value);
        this.StoreGetGoodsList(1);
      }, 500);
    },

    /* 绑定Steam */
    handleBindSteam() {
      this.show_bind = true;
      this.StoreSetUserinfoShow(false);
    },

    /* 显示用户信息 */
    handUserInfo() {
      if (this.state_screen_width < 960) {
        this.StoreSetUserinfoShow(true);
      }
    },
  },
  beforeDestroy() {
    this.bus.off("handleLogin-tip");
  },
};
</script>
<style scoped lang="less">
.home-header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: var(--theme-color-two);
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .unfold {
    display: none;
    font-size: 35px;
    color: var(--gray);
    margin-left: 15px;
  }
  .logo {
    width: 250px;
    height: 70px;
    background-color: var(--theme-color-two);
    border-right: 2px solid var(--theme-color-eight);
    cursor: pointer;
    padding: 5px 15px;
  }
  .input {
    margin-left: 15px;
    width: 240px;
    height: 35px;
    border: 2px solid var(--theme-color-six);
    input {
      width: 90%;
      height: 100%;
      background-color: transparent;
      border: none;
      padding-left: 0.5em;
      color: var(--gray-a);
      &::placeholder {
        color: var(--gray-a);
      }
    }
    @media screen and (max-width: 960px) {
      flex: 1;
    }
  }
  .home {
    font-size: 24px;
    color: var(--gray);
    margin-left: 15px;
    cursor: pointer;
    &:hover {
      color: var(--theme-color-one);
    }
    .iconfont {
      font-size: inherit;
      margin-right: 5px;
    }
  }
  .login {
    position: absolute;
    right: 15px;
    line-height: 40px;
    background-color: var(--theme-color-one);
    padding: 0 20px;
    color: var(--white);
    cursor: pointer;
    border-radius: 5px;
  }
  .user {
    position: absolute;
    right: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    &:hover {
      .menu {
        pointer-events: auto;
        transform: translateY(0);
        opacity: 1;
      }
      img {
        transform-origin: center center;
        transform: translate(-50px, 35px) scale(2);
      }
    }

    .info {
      display: flex;
      align-items: center;
      transform: translateX(-150px);
      pointer-events: none;
      .score {
        display: flex;
        align-items: center;
        font-size: 24px;
        color: var(--theme-color-nine);
        .iconfont {
          font-size: 24px;
          margin-right: 5px;
        }
      }
    }
    img {
      width: 50px;
      height: 50px;
      margin-right: 15px;
      border-radius: 50%;
      transform: translate(0, 0) scale(1);
      transform-origin: left top;
      transition: all 0.5s;
      z-index: 1;
    }
    .name {
      color: var(--gray);
      font-size: 20px;
    }
    .menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 70px;
      right: 25px;
      opacity: 0;
      width: 250px;
      transition: all 0.25s;
      transform: translateY(25px);
      background-color: var(--theme-color-seven);
      border-radius: 0 0 15px 15px;
      pointer-events: none;
      padding-top: 60px;
      .name {
        font-size: 30px;
        margin-bottom: 5px;
      }
      .btns {
        width: 100%;
        padding: 20px;
        .menu-item {
          display: flex;
          justify-content: center;
          color: var(--gray);
          font-size: 22px;
          line-height: 44px;
          cursor: pointer;
          border-radius: 5px;
          &:hover {
            color: var(--theme-color-one);
            background-color: var(--theme-color-six);
          }
        }
      }
    }
  }
  .user-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0;
    top: 70px;
    width: 50vw;
    background-color: #1a1a1a;
    padding: 25px;
    color: var(--gray);
    border-radius: 0 0 0 30px;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .name {
        font-size: 22px;
      }
    }
    .score {
      font-size: 24px;
      color: var(--theme-color-nine);
      margin-bottom: 25px;
      .iconfont {
        font-size: 24px;
        margin-right: 5px;
      }
    }

    .btns {
      width: 100%;
      .menu-item {
        display: flex;
        justify-content: center;
        color: var(--gray);
        font-size: 16px;
        line-height: 32px;
        cursor: pointer;
        border-radius: 5px;
        &:active {
          color: var(--theme-color-one);
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    justify-content: space-between;
    .unfold {
      display: block;
    }
    .logo {
      display: none;
    }
    .input {
      margin-right: 100px;
    }
    .login {
      position: absolute;
      right: 15px;
      margin-left: 35px;
    }
    .home {
      position: absolute;
      font-size: 18px;
    }
    .user {
      position: absolute;
      margin-left: 0px;
      right: 0px;
      &:hover {
        img {
          transform: none;
        }
      }
      .name {
        display: none;
      }
      .info {
        .score {
          display: none;
        }
      }
      img {
        width: 35px;
        height: 35px;
      }
      .menu {
        display: none;
      }
    }
  }
}
</style>
