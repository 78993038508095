import { getReq, postReq } from "./network/index.js";

/* 获取商品分类 */
export const getGoodsType = () => {
  return getReq("/admin/dict/type/goods_type");
};

/* 获取商品列表 */
export const getGoodsList = (params) => {
  return getReq("/mapz/servergoods/listAllPage", params, { headers: { noTip: true } });
};

/* 获取商品详情 */
export const getGoodsDetail = (id) => {
  return getReq("/mapz/servergoods/get?id=" + id);
};

/* 生成订单 */
export const genOrder = (params, other) => {
  return postReq("/mapz/serverorder/gen", params, other);
};

/* 支付积分 */
export const confirmScore = (id) => {
  return getReq("/mapz/serverorder/confirmScore?orderNo=" + id);
};

/* 查询订单状态 */
export const getOrderStatus = (orderNo) => {
  return getReq("/mapz/serverorder/checkOrder?orderNo=" + orderNo, {}, { headers: { noTip: true } });
};

/* 获取订单列表 */
export const getMyOrders = (serverId) => {
  return getReq("mapz/serverorder/myOrders?serverId=" + serverId);
};
