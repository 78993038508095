import Vue from "vue";
import { getGoodsList, getGoodsType } from "@/api/goods.js";
export default {
  namespaced: true,
  state: {
    goods_list: [], //当前商品列表
    goods_name: "", //搜索商品名
    goods_type_name: "全部", //当前展示的类型名
    goods_type: "", //当前展示的商品类型值
    goods_type_list: [], //商品类别列表
    goods_type_name_list: [], //商品类别名称列表
    load_status: "", //列表加载状态
  },
  mutations: {
    // 设置商品类型
    setGoodsType(state, type) {
      state.goods_list = [];
      state.goods_type = type;
      state.goods_type_name = state.goods_type_list.find((item) => {
        return item.value === type;
      }).label;
    },
    // 设置商品搜索关键字
    setGoodsName(state, name) {
      state.goods_list = [];
      state.goods_name = name;
    },
  },
  actions: {
    // 更新商品列表
    getGoodsList(ctx, page) {
      ctx.state.load_status = "加载中...";
      getGoodsList({
        serverId: ctx.rootState.user.server_id,
        type: ctx.state.goods_type,
        name: ctx.state.goods_name,
        current: page,
        size: 50,
      })
        .then((res) => {
          ctx.state.goods_list.push(...res.data.data.records);
          if (res.data.data.records.length) {
            this.page += 1;
            ctx.state.load_status = "";
          } else {
            ctx.state.load_status = "暂无更多";
          }
        })
        .catch(() => {
          ctx.state.load_status = "加载出错了！";
        })
        .finally(() => {
          Vue.prototype.$bus.$emit("loadFinish");
        });
    },
    // 获取商品类型列表
    getGoodsTypeList(ctx) {
      return new Promise((resolve) => {
        getGoodsType().then((res) => {
          ctx.state.goods_type_list = res.data.data;
          ctx.state.goods_type_name_list = res.data.data.map((item) => {
            return item.label;
          });
          ctx.state.goods_type_list.unshift({ label: "全部", value: "" });
          resolve();
        });
      });
    },
  },
};
