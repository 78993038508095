<template>
  <el-dropdown @command="handle">
    <span class="el-dropdown-link"> {{ $t("Language") }}<i class="el-icon-caret-bottom el-icon--right"></i> </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(item, index) of list" :key="index" :command="item.key">{{
        item.name
      }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { localeKeys } from "@/components/i18n/index";

export default {
  name: "change-language",
  data() {
    return {
      list: localeKeys,
    };
  },
  methods: {
    handle(value) {
      this.$i18n.locale = value;
      localStorage.setItem("change-language", value);
      location.reload();
    },
  },
};
</script>
<style scoped lang="less"></style>
