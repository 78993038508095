import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/theme/index.css";
import "@/styles/index.css";
import "@/components/index.js";
import "@/utils/directives.js";
import i18n from "@/components/i18n";

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue(); // event Bus 用于无关系组件间的通信。

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
