export default {
  Language: "English",
  mapz: "Mapz Online Map",
  changeLanguage: "Click to change language",
  isLease: "Is Lease",
  category: "Category",
  leaseTime: "Lease Time(seconds)",
  quantity: "Quantity",
  payType: "Pay Method",
  deliveryType: "Delivery Type",
  direct: "Direct",
  cdk: "CDK",
  total: "Total",
  pay: "Pay",
  yes: "Yes",
  no: "No",
  noBindSteamError: "If you want to use Direct method, please bind steam",
  scoreNotEnoughError: "You don't have enough score",
  createOrderFailed: "Create order failed",
  directNotice:
    "Please confirm that you are on the server and try to avoid making any payments in the few minutes before the server restarts",
  validityPeriod: "Validity period",
  supportPayMethod: "Support the above QR code scanning methods",
  neededScores: "Score You Need To Pay",
  confirmPay: "Confirm Pay",
  successPay: "Success",
  cdkNotice: "This is your CDK, Please keep it safe",
  directTip: "You will get this product in game",
  confirm: "Confirm",
  copyBtn: "Copy",
  attention: "Attention",
  closeNotice: "You want to close this dialog?",
  cancle: "Cancle",
  success: "Success",
  serverIdNotFund: "please copy this link with serverId",
  map: "Map",
  port: "Port",
  price: "Price",
  score: "Score",
  menu: "Menu",
  product: "Product",
  name: "Name",
  orderNo: "OrderNo",
  totalPrice: "TotalPrice",
  money: "Money",
  login: "Login",
  signUp: "Sign Up",
  passwordLogin: "Password",
  codeLogin: "Code",
  username: "Username",
  password: "Password",
  phone: "Phone",
  code: "Code",
  nickname: "NickName",
  orders: "Orders",
  profiles: "Profiles",
  bindSteam: "Bind/UnBind Steam",
  logout: "Logout",
  home: "Home",
  shopInfo: "Shop Info",
  notice: "Notice",
  contact: "Contact Info",
};
